<!--For when you don't quite have the mobile view worked out-->

<template>
    <div class="min-width-scroller" :style="{ '--min-width': minWidth }">
        <slot />
    </div>
</template>

<script lang="ts">
import Vue from '@/vueTyped';

export default Vue.extend({
    props: {
        minWidth: { type: String, default: '40rem' },
    },
});
</script>

<style lang="postcss" scoped>
.min-width-scroller {
    max-width: 100%;
    overflow-x: auto;
    position: relative;
}

.min-width-scroller > :deep(*) {
    min-width: var(--min-width);
}
</style>
