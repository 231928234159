<template>
    <v-dialog
        v-model="internalValue"
        scrollable
        :fullscreen="$vuetify.breakpoint.xsOnly"
        :hide-overlay="$vuetify.breakpoint.xsOnly"
        transition="dialog-bottom-transition"
        content-class="global-overlay-modal-content"
        v-bind="$attrs"
    >
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>

        <div v-if="noCard" class="no-card-container">
            <div class="no-card-close-button-container">
                <base-button icon :aria-label="$t('actions.close')" @click="close">
                    <v-icon class="no-card-close-button-icon">close</v-icon>
                </base-button>
            </div>

            <slot />
        </div>

        <v-card v-else :class="{ mobile: $vuetify.breakpoint.xsOnly }">
            <v-app-bar v-if="$vuetify.breakpoint.xsOnly" fixed dark color="secondary">
                <v-btn icon dark :aria-label="$t('actions.close')" @click="close()">
                    <v-icon>arrow_back</v-icon>
                </v-btn>
                <v-toolbar-title>
                    <slot name="title" />
                </v-toolbar-title>
                <v-spacer />
                <slot name="extra" />
            </v-app-bar>
            <v-card-title v-if="!$vuetify.breakpoint.xsOnly">
                <v-row no-gutters>
                    <v-col>
                        <h2 class="pt-4 t-subtitle">
                            <slot name="title" />
                        </h2>
                    </v-col>
                    <v-col cols="auto">
                        <slot name="extra" />
                        <v-btn icon color="primary" :aria-label="$t('actions.close')" @click="close()">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text class="content">
                <slot />
                <slot name="content" />

                <action-footer v-if="$slots.actions" class="text-center">
                    <slot name="actions" />
                </action-footer>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script lang="ts">
import ActionFooter from '@/components/ActionFooter.vue';
import Vue from '@/vueTyped';

export default Vue.extend({
    name: 'OverlayModal',
    components: {
        ActionFooter,
    },
    props: {
        value: {
            required: true,
            type: Boolean,
            default: false,
        },
        noCard: Boolean,
    },
    computed: {
        internalValue: {
            get(): boolean {
                return (this as any).value;
            },
            set(internalValue) {
                (this as any).$emit('input', internalValue);
            },
        },
    },
    methods: {
        close() {
            this.$emit('input', false);
        }
    },
});
</script>

<style lang="postcss">
.global-overlay-modal-content {
    flex-direction: column;
    overscroll-behavior: contain;
}
</style>

<style lang="postcss" scoped>
.mobile .content {
    margin-top: var(--spacing-12);
    &.v-card__text {
        padding: var(--spacing-8) var(--spacing-4) 0px var(--spacing-4);
    }
}

.no-card-container {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    position: relative;
}

.no-card-close-button-container {
    position: absolute;
    right: var(--spacing-1);
    top: var(--spacing-1);
    z-index: 2;
}

.no-card-close-button-icon {
    color: white !important;
}

</style>

