<template>
    <base-page-content>
        <min-width-scroller>
            <v-row justify="space-between">
                <v-col cols="auto">
                    <router-link :to="{ name: 'client-user-groups-index', params: $route.params }">&larr; Back to user groups</router-link>
                </v-col>

                <v-col cols="auto" class="text-end">
                    <template v-if="invitationLinkHref">
                        <div>
                            Invitation link
                            <div class="invitation-link-wrapper">
                                <div class="mx-2">{{ invitationLinkHref }}</div>
                                <base-button small @click="copyInvitationLink">
                                    <v-icon small left>
                                        {{ invitationLinkCopied === 0 ? 'content_copy' : 'check' }}
                                    </v-icon>
                                    Copy
                                </base-button>
                            </div>
                        </div>
                        <small>Link expires {{ new Date(userGroup.inviteLink.expiresAt).toDateString() }}</small>
                    </template>

                    <template v-else>
                        <base-button
                            small
                            color="primary"
                            :disabled="generatingInviteToken > 0"
                            @click="generateInvitationLink"
                        >Generate invitation link</base-button>
                        <div v-if="invitationLinkExpired">
                            <small>Your previous invite link has expired</small>
                        </div>
                    </template>
                </v-col>
            </v-row>

            <loading-indicator v-if="loadingUserGroup > 0" />

            <div v-else-if="userGroupLoadError">
                <p>{{ userGroupLoadError }}</p>
            </div>

            <template v-else>
                <content-block>
                    <div class="content-box">
                        <div class="boxed-content">
                            <v-row justify="space-between" dense>
                                <v-col cols="auto">
                                    <h2>{{ userGroup.name }}</h2>
                                </v-col>

                                <v-col cols="auto">
                                    Date created
                                    {{ new Date(userGroup.createdAt).toLocaleDateString() }}
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="auto">
                                    <big-value label="No. of members">{{ userGroup.memberCount }}</big-value>
                                </v-col>

                                <v-col cols="auto">
                                    <big-value label="Active surveys">{{ userGroup.activeSurveyCount }}</big-value>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </content-block>
            </template>

            <loading-indicator v-if="loadingMembers > 0" />

            <div v-else-if="membersLoadError">
                <p>{{ membersLoadError }}</p>
            </div>

            <template v-else-if="members.length > 0">
                <content-block>
                    <div class="content-box">
                        <div class="boxed-content">
                            <h3 class="mb-6">Member list</h3>

                            <data-table
                                :head="[
                                    { label: 'User name', TODO_sort: 'name' },
                                    { label: 'Sign up date', TODO_sort: 'date' },
                                    { label: 'Posts/comments', TODO_sort: 'activity' },
                                    { label: 'Contribution date', TODO_sort: 'date' },
                                ]"
                                default-sort="activity"
                            >
                                <tbody>
                                    <tr v-for="member in userMembers" :key="member.user.id">
                                        <th scope="row">
                                            <router-link :to="{ name: 'user.detail', params: { id: member.user.id } }">
                                                {{ member.user.firstName }} {{ member.user.lastName }}
                                            </router-link>
                                        </th>
                                        <td>{{ new Date(member.createdAt).toLocaleString() }}</td>
                                        <td>{{ member.user.totalPosts + member.user.totalComments }}</td>
                                        <td>
                                            <template v-if="member.user.lastPostedAt || member.user.lastCommentedAt">
                                                {{ new Date([member.user.lastPostedAt, member.user.lastCommentedAt].sort()[0]).toLocaleString() }}
                                            </template>
                                            <template v-else>Never</template>
                                        </td>
                                    </tr>
                                </tbody>
                            </data-table>
                        </div>
                    </div>
                </content-block>
            </template>

            <loading-indicator v-if="loadingSurveys > 0" />

            <div v-else-if="surveysLoadError">
                <!-- We're getting a 401 for this, which I don't think is right, so just hide this for now. -->
                <!-- <p>{{ surveysLoadError }}</p> -->
            </div>

            <template v-else-if="surveys.length > 0">
                <content-block>
                    <div class="content-box">
                        <div class="boxed-content">
                            <v-row>
                                <v-col>
                                    <h3>Active surveys</h3>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col v-for="survey in surveys" :key="survey.id" cols="6">
                                    <div class="content-box">
                                        <div class="boxed-content">
                                            <h4>
                                                <router-link :to="{ name: 'client-survey-details', params: { surveyId: survey.id } }">{{ survey.title }}</router-link>
                                            </h4>

                                            <v-row>
                                                <v-col cols="auto">
                                                    <big-value label="No. of questions">{{ survey.surveyQuestions ? survey.surveyQuestions.length : 0 }}</big-value>
                                                </v-col>

                                                <v-col cols="auto">
                                                    <big-value label="No. of requests">{{ survey.totalSurveyRequests }}</big-value>
                                                </v-col>
                                            </v-row>

                                            <template v-if="survey.totalSurveyRequests !== 0">
                                                <v-row>
                                                    <v-col cols="auto">
                                                        <big-value label="Completion rate">{{ Math.round(survey.totalSurveyResponses / survey.totalSurveyRequests * 100) }}%</big-value>
                                                    </v-col>

                                                    <v-col cols="auto">
                                                        <big-value label="No. of responses">{{ survey.totalSurveyResponses }}</big-value>
                                                    </v-col>
                                                </v-row>
                                            </template>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </content-block>
            </template>
        </min-width-scroller>
    </base-page-content>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import BigValue from '@/ui/BigValue.vue';
import ContentBlock from '@/ui/ContentBlock.vue';
import DataTable from '@/components/DataTable.vue';
import { Survey, UserGroup, UserGroupMember } from '@/types';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import MinWidthScroller from '@/ui/MinWidthScroller.vue';
import { CLIENT_ORIGIN } from '@/config';

export default Vue.extend({
    components: {
        BigValue,
        ContentBlock,
        DataTable,
        LoadingIndicator,
        MinWidthScroller,
    },

    props: {
        userGroupId: { type: String, required: true },
    },

    data() {
        return {
            loadingUserGroup: 0,
            userGroupLoadError: null as Error | null,
            userGroup: null as UserGroup | null,
            loadingSurveys: 0,
            surveysLoadError: null as Error | null,
            surveys: [] as Survey[],
            loadingMembers: 0,
            membersLoadError: null as Error | null,
            members: [] as UserGroupMember[],
            generatingInviteToken: 0,
            invitationLinkCopied: 0,
        };
    },

    computed: {
        invitationLinkExpired(): boolean {
            return new Date(this.userGroup?.inviteLink?.expiresAt ?? 0) < new Date();
        },

        invitationLinkHref(): string | null {
            const inviteToken = this.userGroup?.inviteLink?.token;

            if (!this.userGroup || !inviteToken || this.invitationLinkExpired) {
                return null;
            }

            return CLIENT_ORIGIN + this.$router.resolve({
                name: 'join-user-group',
                params: { userGroupId: this.userGroup.id },
                hash: `#${inviteToken}`,
            }).href;
        },

        userMembers(): UserGroupMember[] {
            return this.members.filter(member => member.user?.id);
        },
    },

    watch: {
        userGroupId: {
            immediate: true,
            handler() {
                this.fetchUserGroup();
                this.fetchMembers(String(this.$route.query.sort));
                this.fetchSurveys();
            },
        },

        '$route.query.sort'(sort) {
            this.fetchMembers(String(sort) || 'activity');
        },
    },

    methods: {
        async fetchUserGroup() {
            try {
                this.loadingUserGroup += 1;
                const { data } = await this.$store.state.apiClient.get(`/user-groups/${this.userGroupId}`);
                this.userGroup = data.userGroup;
            } catch (error) {
                this.userGroupLoadError = error;
                console.error(error);
            } finally {
                this.loadingUserGroup -= 1;
            }
        },

        async fetchMembers(sort: string) {
            try {
                this.loadingMembers += 1;

                if (sort.startsWith('-')) {
                //     sort = `${sort.slice(1)} DESC`;
                }

                // TODO: convert `sort` query value into the one the API expects.

                const { data } = await this.$store.state.apiClient.get(`/user-groups/${this.userGroupId}/members?sort=${'createdAt'}`);
                this.members = data.userGroupMembers;
            } catch (error) {
                this.membersLoadError = error;
                console.error(error);
            } finally {
                this.loadingMembers -= 1;
            }
        },

        async fetchSurveys() {
            try {
                this.loadingSurveys += 1;
                const { data } = await this.$store.state.apiClient.get(`/surveys?userGroupId=${this.userGroupId}`);
                this.surveys = data.surveys;
            } catch (error) {
                this.surveysLoadError = error;
                console.error(error);
            } finally {
                this.loadingSurveys -= 1;
            }
        },

        async generateInvitationLink() {
            if (!this.userGroup) {
                throw new Error('Attempted to generate an invite link without a user group');
            }

            this.generatingInviteToken += 1;
            const { data } = await this.$store.state.apiClient.post(`/user-groups/${this.userGroupId}/invite-token/generate`);
            this.$set(this.userGroup, 'inviteLink', data.inviteLink);
            this.generatingInviteToken -= 1;
        },

        async copyInvitationLink() {
            if (this.invitationLinkHref) {
                try {
                    await navigator.clipboard.writeText(this.invitationLinkHref);
                    this.invitationLinkCopied += 1;
                    setTimeout(() => {
                        this.invitationLinkCopied -= 1;
                    }, 1500);
                } catch (error) {
                    alert(error);
                }
            }
        },
    },
});
</script>

<style lang="postcss" scoped>
.invitation-link-wrapper {
    border: 1px solid var(--color-primary-light);
    border-radius: 1em;
    display: inline-flex;
    padding: 0.3ch 1ch;
}

.page-box {
    margin: var(--spacing-8) 0;
}

.content-box {
    border: 1px solid #8886;
    border-radius: 1ch;
}

.boxed-content {
    margin: var(--spacing-8);
}
</style>
