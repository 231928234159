<template>
    <base-page-content>
        <min-width-scroller>
            <data-table pill-rows>
                <thead>
                    <tr>
                        <th>User group name</th>
                        <th>Creation date</th>
                        <th class="text-center">No. of members</th>
                        <th v-if="totalActiveSurveyCount > 0" class="text-center">No. of surveys running</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="loading > 0">
                        <td colspan="4">
                            <div style="min-height: 5rem;" />
                            <loading-indicator />
                        </td>
                    </tr>
                    <tr v-else-if="error">
                        <td colspan="4">{{ error }}</td>
                    </tr>
                    <tr v-else-if="groupsOwnedByClient.length + ownedGroupsWithNoClient.length === 0">
                        <td colspan="4">No user groups</td>
                    </tr>
                    <template v-else>
                        <tr v-for="group in groupsOwnedByClient" :key="group.id" :class="`linked-row`">
                            <th scope="row">
                                <router-link :to="{ name: 'client-user-group-details', params: { ...$route.params, userGroupId: group.id } }" class="row-link">
                                    {{ group.name }}
                                </router-link>
                            </th>
                            <td>{{ new Date(group.createdAt).toLocaleDateString() }}</td>
                            <td class="text-center">{{ group.memberCount }}</td>
                            <td v-if="totalActiveSurveyCount > 0" class="text-center">1</td>
                        </tr>

                        <template v-if="ownedGroupsWithNoClient.length !== 0">
                            <tr v-for="group in ownedGroupsWithNoClient" :key="group.id" :class="`linked-row`">
                                <th scope="row">
                                    <router-link :to="{ name: 'client-user-group-details', params: { userGroupId: group.id } }" class="row-link">
                                        {{ group.name }}
                                        <small>(No client group)</small>
                                    </router-link>
                                </th>
                                <td>{{ new Date(group.createdAt).toLocaleDateString() }}</td>
                                <td class="text-center">{{ group.memberCount }}</td>
                                <td v-if="totalActiveSurveyCount > 0" class="text-center">1</td>
                            </tr>
                        </template>
                    </template>
                </tbody>
            </data-table>
        </min-width-scroller>
    </base-page-content>
</template>

<script lang="ts">
import DataTable from '@/components/DataTable.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import { ClientGroup, UserGroup } from '@/types';
import MinWidthScroller from '@/ui/MinWidthScroller.vue';
import Vue from '@/vueTyped';

export default Vue.extend({
    components: {
        DataTable,
        LoadingIndicator,
        MinWidthScroller,
    },

    props: {
        clientGroup: { type: Object as () => ClientGroup, required: true },
    },

    data() {
        return {
            loading: 0,
            error: null as Error | null,
            userGroups: [] as UserGroup[],
        };
    },

    computed: {
        ownedUserGroupIds(): UserGroup['id'][] {
            const groups = this.$store.state.account.currentUser?.userGroups ?? [];
            return groups.filter(ug => ug.is_admin || ug.is_owner).map(ug => ug.id);
        },

        totalActiveSurveyCount(): number {
            return this.userGroups.reduce((total, ug) => total + ug.activeSurveyCount, 0);
        },

        groupsOwnedByClient(): UserGroup[] {
            return this.userGroups.filter((ug: UserGroup) => {
                const isInCurrentClientGroup = ug.owners?.find(owner => {
                    return owner.clientGroup?.id === this.clientGroup.id;
                });
                return isInCurrentClientGroup;
            });
        },

        ownedGroupsWithNoClient(): UserGroup[] {
            return this.userGroups.filter((ug: UserGroup) => {
                const isOwnedByUser = this.ownedUserGroupIds.includes(ug.id);
                const hasNoClientGroup = !ug.owners?.find(owner => owner.clientGroup);
                return isOwnedByUser && hasNoClientGroup;
            });
        },
    },

    watch: {
        '$store.state.account.currentUser.id': {
            immediate: true,
            handler: 'fetchUserGroups',
        },

        'clientGroup.id': {
            immediate: true,
            handler: 'fetchUserGroups',
        },
    },

    methods: {
        async fetchUserGroups() {
            try {
                this.loading += 1;
                this.userGroups = [];
                const { data } = await this.$store.state.apiClient.get('/user-groups');
                this.userGroups = data.userGroups;
            } catch (error) {
                this.error = error;
            } finally {
                this.loading -= 1;
            }
        }
    },
});
</script>

<style lang="postcss" scoped>
.row-link {
    font-weight: bold;
    text-decoration: none;
}

</style>
