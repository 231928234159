<template>
    <span class="sort-indicator">
        <slot />

        <v-icon
            small
            class="icon"
            :data-inline="inline"
            :data-active="active"
            :data-reversed="reversed"
        >north</v-icon>
    </span>
</template>

<script lang="ts">
import Vue from '@/vueTyped';

export default Vue.extend({
    props: {
        currentSort: {
            type: String,
            default: '',
        },

        value: {
            type: String,
            default: '',
        },

        inline: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        active(): boolean {
            return this.value !== '' && this.currentSort.endsWith(this.value);
        },

        reversed(): boolean {
            return this.active && this.currentSort.startsWith('-');
        },
    },
});
</script>

<style lang="postcss" scoped>
.sort-indicator {
    white-space: nowrap;
}

.icon {
    color: var(--color-tertiary);
    transition: margin-inline-end 0.2s, transform 0.3s;
    width: 1ch;
}

.icon:not([data-inline]) {
    /* No top/right/bottom/left, this just takes it out of the normal flow. */
    position: absolute;
}


.icon:not([data-active]) {
    visibility: hidden;
}

.icon[data-inline]:not([data-active]) {
    margin-inline-end: -1ch;
}

.icon[data-reversed] {
    transform: rotate(180deg);
}
</style>
