<template>
    <base-page-content>
        <template v-if="survey">
            <p style="color: red; font-weight: bold;">
                Data entered here will not be saved at this point!<br>
                You'll have to save your changes (available at the bottom of this page) manually!
            </p>

            <content-block>
                <label>
                    <b>Title</b>
                    <input v-model="survey.title" type="text">
                </label>
            </content-block>
            <content-block>
                <label>
                    <b>Description</b>
                    <textarea v-model="survey.description" />
                </label>
            </content-block>
            <content-block>
                <label>
                    <input v-model="survey.settings.containsSensitiveData" type="checkbox">{{ ' ' }}
                    <b>Contains sensitive data</b>
                </label>
            </content-block>
            <content-block>
                <label>
                    <b>Default request message</b>
                    <textarea v-model="survey.settings.defaultRequestMessage" />
                    <small>Scheduled SMS prompts will use this text</small>
                </label>
            </content-block>

            <b>Questions</b>
            <ol :start="0">
                <content-block v-for="(question, qi) of survey.surveyQuestions" :key="question.id" tag="li">
                    <content-box>
                        <content-block>
                            <table>
                                <tbody>
                                    <tr>
                                        <td />
                                        <layout-row tag="td" justify="space-between">
                                            <layout-column>
                                                <b>Move</b>{{ ' ' }}
                                                <button type="button" :disabled="qi === 0" @click="moveQuestion(question, -1)">Up</button>{{ ' ' }}
                                                <button type="button" :disabled="qi === survey.surveyQuestions.length - 1" @click="moveQuestion(question, +1)">Down</button>{{ ' ' }}
                                            </layout-column>
                                            <layout-column>
                                                <b>Delete</b>{{ ' ' }}
                                                <button type="button" @click="deleteQuestion(question)">&times;</button>{{ ' ' }}
                                            </layout-column>
                                        </layout-row>
                                    </tr>

                                    <id-provider v-slot="{ id }" tag="tr">
                                        <td><label :for="id"><b>Title</b></label></td>
                                        <td><input :id="id" v-model="question.title" type="text"></td>
                                    </id-provider>
                                    <id-provider v-slot="{ id }" tag="tr">
                                        <td><label :for="id"><b>Subtitle</b></label></td>
                                        <td><input :id="id" v-model="question.subtitle" type="text"></td>
                                    </id-provider>
                                    <id-provider v-slot="{ id }" tag="tr">
                                        <td><input :id="id" v-model="question.is_required" type="checkbox"></td>
                                        <td><label :for="id"><b>Required</b></label></td>
                                    </id-provider>
                                    <tr>
                                        <td><b>Visible</b></td>
                                        <td>
                                            <label>
                                                <input
                                                    type="radio"
                                                    :name="`${question.id} condition`"
                                                    :checked="question.on_condition === null"
                                                    @click="question.on_condition = null"
                                                >{{ ' ' }}
                                                Always
                                            </label><br>
                                            <label>
                                                <input
                                                    type="radio"
                                                    :name="`${question.id} condition`"
                                                    :checked="question.on_condition && question.on_condition[0] === 'ANSWERED_AFFIRMATIVE'"
                                                    @click="question.on_condition = ['ANSWERED_AFFIRMATIVE']"
                                                >{{ ' ' }}
                                                When another question is answered affirmatively...
                                            </label><br>
                                            <div v-if="question.on_condition && question.on_condition[0] === 'ANSWERED_AFFIRMATIVE'" class="condition-rule">
                                                <label>
                                                    The related question<br>
                                                    <select v-model="question.on_condition[1]">
                                                        <template v-for="otherQuestion of survey.surveyQuestions">
                                                            <option v-if="otherQuestion !== question" :key="otherQuestion.id" :value="otherQuestion.id">{{ otherQuestion.title }}</option>
                                                        </template>
                                                    </select>
                                                </label>
                                            </div>
                                            <label>
                                                <input
                                                    type="radio"
                                                    :name="`${question.id} condition`"
                                                    :checked="question.on_condition && question.on_condition[0] === 'ANSWER_EQUALS'"
                                                    @click="question.on_condition = ['ANSWER_EQUALS']"
                                                >{{ ' ' }}
                                                When another question's answer equals...
                                            </label><br>
                                            <div v-if="question.on_condition && question.on_condition[0] === 'ANSWER_EQUALS'" class="condition-rule">
                                                <label>
                                                    The related question<br>
                                                    <select v-model="question.on_condition[1]">
                                                        <template v-for="otherQuestion of survey.surveyQuestions">
                                                            <option v-if="otherQuestion !== question" :key="otherQuestion.id" :value="otherQuestion.id">{{ otherQuestion.title }}</option>
                                                        </template>
                                                    </select>
                                                </label><br>
                                                <template v-for="otherQuestion of [survey.surveyQuestions.find(q => q.id === question.on_condition[1])]">
                                                    <template v-if="otherQuestion">
                                                        <label :key="`${otherQuestion.id}`">
                                                            The answer<br>
                                                            <select v-if="Array.isArray(otherQuestion.options)" v-model="question.on_condition[2]">
                                                                <option v-for="otherOption of otherQuestion.options" :key="otherOption.value" :value="otherOption.value">{{ otherOption.label }}</option>
                                                            </select>
                                                            <input v-else v-model="question.on_condition[2]" type="text">
                                                        </label>
                                                    </template>
                                                </template>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Type</b></td>
                                        <td>
                                            <label><input v-model="question.question_type" type="radio" :name="`${question.id} type`" value="text"> Text input</label><br>
                                            <div v-if="question.question_type === 'text'" class="condition-rule">
                                                <label>
                                                    <input v-model="question.options.multiline" type="checkbox">{{ ' ' }}
                                                    Allow multiple lines
                                                </label>
                                            </div>
                                            <label><input v-model="question.question_type" type="radio" :name="`${question.id} type`" value="radio"> Radio buttons</label><br>
                                            <label><input v-model="question.question_type" type="radio" :name="`${question.id} type`" value="checkbox"> Checkboxes</label>


                                            <div v-if="question.question_type === 'radio' || question.question_type === 'checkbox'" class="condition-rule">
                                                <ol v-if="Array.isArray(question.options)" :start="0">
                                                    <content-block v-for="(option, oi) of question.options" :key="getKeyFor(option)" tag="li">
                                                        <table class="question-option-table">
                                                            <tbody>
                                                                <tr>
                                                                    <td />
                                                                    <layout-row tag="td" justify="space-between">
                                                                        <layout-column>
                                                                            <b>Move</b>{{ ' ' }}
                                                                            <button type="button" :disabled="oi === 0" @click="moveItem(question.options, option, -1)">Up</button>{{ ' ' }}
                                                                            <button type="button" :disabled="oi === question.options.length - 1" @click="moveItem(question.options, option, +1)">Down</button>{{ ' ' }}
                                                                        </layout-column>
                                                                        <layout-column>
                                                                            <b>Delete</b>{{ ' ' }}
                                                                            <button type="button" @click="deleteOption(question, option)">&times;</button>{{ ' ' }}
                                                                        </layout-column>
                                                                    </layout-row>
                                                                </tr>
                                                                <id-provider v-slot="{ id }" tag="tr">
                                                                    <td><label :for="id"><b>Label</b></label></td>
                                                                    <td>
                                                                        <input :id="id" v-model="option.label" type="text">
                                                                        <small>Labels are human-readable</small>
                                                                    </td>
                                                                </id-provider>
                                                                <id-provider v-slot="{ id }" tag="tr">
                                                                    <td><label :for="id"><b>Value</b></label></td>
                                                                    <td>
                                                                        <input :id="id" v-model="option.value" type="text">
                                                                        <small>Values should be unique and short</small>
                                                                    </td>
                                                                </id-provider>
                                                                <id-provider v-slot="{ id }" tag="tr">
                                                                    <td><input :id="id" v-model="option.input" type="checkbox"></td>
                                                                    <td><label :for="id">Allow arbitrary text input</label></td>
                                                                </id-provider>
                                                                <id-provider v-if="option.input" v-slot="{ id }" tag="tr">
                                                                    <td><input :id="id" v-model="option.multiline" type="checkbox"></td>
                                                                    <td><label :for="id">Allow multiple lines</label></td>
                                                                </id-provider>
                                                            </tbody>
                                                        </table>
                                                    </content-block>
                                                </ol>

                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <div style="text-align: center">
                                <button type="button" @click="addOption(question)">Add new option</button>
                            </div>
                        </content-block>
                    </content-box>
                </content-block>

                <div style="text-align: center">
                    <button type="button" @click="addQuestion">Add new question</button>
                </div>
            </ol>

            <content-block>
                <label>
                    <b>Current state</b>
                    <textarea
                        :value="currentState"
                        :rows="currentState.split('\n').length + 1"
                        readonly
                        style="font-family: monospace; overflowe: auto; white-space: pre;"
                    />
                </label>
            </content-block>
        </template>
    </base-page-content>
</template>

<script lang="ts">
import { RadioCheckboxSurveyQuestion, Survey, SurveyQuestion, TextSurveyQuestion } from '@/types';
import ContentBlock from '@/ui/ContentBlock.vue';
import ContentBox from '@/ui/ContentBox.vue';
import IdProvider from '@/ui/IdProvider.vue';
import LayoutColumn from '@/ui/LayoutColumn.vue';
import LayoutRow from '@/ui/LayoutRow.vue';
import Vue from '@/vueTyped';

const TEMPORARY_KEYS = new WeakMap<object, number>();

function randomId(length = 24) {
    return [
        Math.random().toString(36).split('.')[1],
        Math.random().toString(36).split('.')[1],
        Math.random().toString(36).split('.')[1]
    ].join('').slice(0, length);
}

export default Vue.extend({
    components: {
        ContentBlock,
        ContentBox,
        IdProvider,
        LayoutColumn,
        LayoutRow,
    },

    props: {
        surveyId: { type: String as () => Survey['id'], required: true },
    },

    data() {
        return {
            survey: null as Survey | null,
            questionOptionsCache: {} as Record<SurveyQuestion['id'], {
                text?: TextSurveyQuestion['options'],
                radioCheckbox?: RadioCheckboxSurveyQuestion['options']
            }>
        };
    },

    computed: {
        currentState(): string {
            const { title, description, settings } = this.survey!;
            const surveyQuestions = this.survey!.surveyQuestions!.map(question => {
                const { order, title, subtitle, is_required, on_condition, question_type, options } = question;
                return { order, title, subtitle, is_required, on_condition, question_type, options };
            });
            return JSON.stringify({ title, description, settings, surveyQuestions }, null, 2);
        },
    },

    watch: {
        surveyId: {
            immediate: true,
            handler() {
                this.fetchSurvey();
            },
        },

        'survey.surveyQuestions': {
            immediate: true,
            deep: true,
            handler() {
                this.syncQuestionOrders();
                this.syncQuestionOptions();
            },
        },
    },

    methods: {
        async fetchSurvey() {
            const { data } = await this.$store.state.apiClient.get(`/surveys/${this.surveyId}`);
            const survey: Survey = data.survey;
            survey.surveyQuestions ??= [];
            survey.surveyQuestions.sort((q1, q2) => (q1.order ?? 0) - (q2.order ?? 0));
            this.survey = survey;
        },

        syncQuestionOrders() {
            this.survey?.surveyQuestions?.forEach((q, i) => q.order = i);
        },

        syncQuestionOptions() {
            this.survey?.surveyQuestions?.forEach(question => {
                this.questionOptionsCache[question.id] ??= {};

                if (question.question_type === 'text') {
                    this.questionOptionsCache[question.id].text ??= { multiline: false };
                    if (!Array.isArray(question.options)) {
                        this.questionOptionsCache[question.id].text = question.options;
                    } else {
                        this.questionOptionsCache[question.id].radioCheckbox = question.options;
                        question.options = this.questionOptionsCache[question.id].text!;
                    }
                }

                if (question.question_type === 'radio' || question.question_type === 'checkbox') {
                    this.questionOptionsCache[question.id].radioCheckbox ??= [];
                    if (Array.isArray(question.options)) {
                        this.questionOptionsCache[question.id].radioCheckbox = question.options;
                    } else {
                        this.questionOptionsCache[question.id].text = question.options;
                        question.options = this.questionOptionsCache[question.id].radioCheckbox!;
                    }
                }
            });
        },

        moveQuestion(question: SurveyQuestion, shift: number) {
            this.moveItem(this.survey?.surveyQuestions!, question, shift);
        },

        addQuestion() {
            this.survey!.surveyQuestions!.push({
                id: randomId(),
                order: this.survey!.surveyQuestions!.length,
                survey_id: this.surveyId,
                title: '',
                subtitle: '',
                is_required: false,
                on_condition: null,
                question_type: 'text',
                options: { multiline: false },
            });
        },

        deleteQuestion(question: SurveyQuestion) {
            for (const otherQuestion of this.survey!.surveyQuestions!) {
                if (
                    (
                        otherQuestion.on_condition?.[0] === 'ANSWER_EQUALS' ||
                        otherQuestion.on_condition?.[0] === 'ANSWERED_AFFIRMATIVE'
                    ) &&
                    otherQuestion.on_condition.includes(question.id)
                ) {
                    otherQuestion.on_condition = null;
                }
            }
            const index = this.survey!.surveyQuestions!.indexOf(question);
            this.survey!.surveyQuestions!.splice(index, 1);
        },

        addOption(question: RadioCheckboxSurveyQuestion) {
            if (Array.isArray(question.options)) question.options.push({ value: '', label: '' });
        },

        deleteOption(question: RadioCheckboxSurveyQuestion, option: NonNullable<typeof question.options>[number]) {
            for (const otherQuestion of this.survey!.surveyQuestions!) {
                if (
                    (
                        otherQuestion.on_condition?.[0] === 'ANSWER_EQUALS' ||
                        otherQuestion.on_condition?.[0] === 'ANSWERED_AFFIRMATIVE'
                    ) &&
                    otherQuestion.on_condition.includes(question.id) &&
                    otherQuestion.on_condition.includes(option.value)
                ) {
                    otherQuestion.on_condition = null;
                }
            }
            const index = question.options!.indexOf(option);
            question.options!.splice(index, 1);
        },

        moveItem(items: unknown[], item: SurveyQuestion, shift: number) {
            const index = items.indexOf(item);
            items.splice(index, 1);
            items.splice(index + shift, 0, item);
        },

        getKeyFor(object: object) {
            if (!TEMPORARY_KEYS.has(object)) TEMPORARY_KEYS.set(object, Math.random());
            return TEMPORARY_KEYS.get(object);
        },
    },
});
</script>

<style lang="postcss" scoped>
:disabled {
    opacity: 0.4;
}

ol {
    list-style: none;
    padding: 0;
}

button,
select {
    border: 1px solid gray;
    border-radius: 0.3em;
    box-shadow: 1px 1px 1px #0003;
    font-weight: bold;
    padding: 0 1ch;
}

select {
    appearance: revert;
}

input[type="text"],
textarea {
    border: 1px solid gray;
    box-shadow: 1px 1px 1px #0003 inset;
    display: block;
    padding: 0.5ch 1ch;
    width: 100%;
}

table {
    border-spacing: 1ch;
    table-layout: fixed;
    width: 100%;
}

table td {
    vertical-align: baseline;
}

table td:first-child {
    width: 7ch;
    text-align: end;
}

.condition-rule {
    border-inline-start: 1px solid #8888;
    margin-inline-start: 0.5ch;
    padding-inline-start: 1ch;
    margin-block-end: 1ch;
}
</style>
