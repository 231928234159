<template>
    <table :class="{ 'pill-rows': pillRows }">
        <thead v-if="head">
            <tr>
                <th v-for="cell in head" :key="`${cell.label}`">
                    <component
                        :is="cell.sort ? 'router-link' : 'span'"
                        :to="{
                            query: {
                                ...$route.query,
                                [pageParam]: undefined,
                                [sortParam]: currentSort === cell.sort ? `-${cell.sort}` : cell.sort,
                            },
                        }"
                        replace
                        class="sort-link"
                        :data-active="[`-${cell.sort}`, cell.sort].includes(currentSort)"
                    >
                        <data-table-sort-indicator :current-sort="currentSort" :value="cell.sort" inline>
                            {{ cell.label }}
                        </data-table-sort-indicator>
                    </component>
                </th>
            </tr>
        </thead>

        <slot />
    </table>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import DataTableSortIndicator from './DataTableSortIndicator.vue';

export default Vue.extend({
    components: {
        DataTableSortIndicator,
    },

    props: {
        sortParam: {
            type: String,
            default: 'sort',
        },

        pageParam: {
            type: String,
            default: 'page',
        },

        head: {
            type: Array as () => { label: string, sort?: string }[],
            default: () => [],
        },

        defaultSort: {
            type: String,
            default: '',
        },

        pillRows: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {};
    },

    computed: {
        currentSort(): string {
            let defaultSort = this.defaultSort || this.head?.find(cell => cell.sort)?.sort;
            return String(this.$route.query[this.sortParam] ?? defaultSort ?? '');
        }
    },
});
</script>

<style lang="postcss" scoped>
table {
    border-spacing: 0;
    width: 100%;
}

.pill-rows {
    border-spacing: 0 var(--spacing-4);
}

thead {
    font-size: var(--type-interface);
    line-height: 1.2;
}

th {
    font-weight: inherit;
    text-align: start;
}

thead th {
    letter-spacing: 0.1ch;
    opacity: 0.7;
    text-transform: uppercase;
}

.sort-link {
    color: inherit;
    display: inline-block;
    margin: calc(-1 * var(--spacing-3)) calc(-1 * var(--spacing-3));
    padding: var(--spacing-3) var(--spacing-3);
    text-decoration: none;
}

.sort-link[data-active] {
    font-weight: bold;
}

th,
td {
    padding-inline:  var(--spacing-4);
}

td {
    padding-block: var(--spacing-3);
}

tr > :first-child {
    padding-inline-start: var(--spacing-8);
}

tr > :last-child {
    padding-inline-end: var(--spacing-8);
}

tbody > tr > * {
    border-block-start: 1px solid #8884;
}

.pill-rows > tbody > tr > * {
    background: white;
    border: 0 solid #8883;
    border-block-width: 1px;
}

.pill-rows > tbody > tr > *:first-child {
    border-inline-start-width: 1px;
    border-top-left-radius: var(--spacing-4);
    border-bottom-left-radius: var(--spacing-4);
}

.pill-rows > tbody > tr > *:last-child {
    border-inline-end-width: 1px;
    border-top-right-radius: var(--spacing-4);
    border-bottom-right-radius: var(--spacing-4);
}

.pill-rows > tbody > tr {
    filter: drop-shadow(0 0 0 #0003);
    transition: filter 0.3s;
}
</style>
