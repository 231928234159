<template>
    <div>
        <div>{{ label }}</div>
        <div class="big-value">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
import Vue from '@/vueTyped';

export default Vue.extend({
    props: {
        label: { type: String, default: '•' },
    },
});
</script>

<style lang="postcss" scoped>
.big-value {
    color: var(--color-primary);
    font-size: var(--type-title);
    font-weight: bold;
}
</style>
