<template>
    <table>
        <thead>
            <tr>
                <th>
                    Name and
                    <router-link
                        :to="{ query: { ...$route.query, page: undefined, sort: sort === '-signed-up' ? 'signed-up' : '-signed-up' } }"
                        replace
                        class="sort-link"
                        :data-active="sort.endsWith('signed-up')"
                    >
                        sign-up
                        <data-table-sort-indicator :current-sort="sort" value="signed-up">date</data-table-sort-indicator>
                    </router-link>
                </th>

                <th>
                    <router-link
                        :to="{ query: { ...$route.query, page: undefined, sort: sort === '-contributed' ? 'contributed' : '-contributed' } }"
                        replace
                        class="sort-link"
                        :data-active="sort.endsWith('contributed')"
                    >
                        Last
                        <data-table-sort-indicator :current-sort="sort" value="contributed">contribution</data-table-sort-indicator>
                    </router-link>
                </th>

                <th v-if="lastColumn">
                    <router-link
                        :to="{ query: { ...$route.query, page: undefined, sort: sort === '-contributions' ? 'contributions' : '-contributions' } }"
                        replace
                        class="sort-link"
                        :data-active="sort.endsWith('contributions')"
                    >
                        <data-table-sort-indicator :current-sort="sort" value="contributions">
                            <template v-if="lastColumn === 'posts'">
                                Posts
                            </template>
                            <template v-else-if="lastColumn === 'comments'">
                                Comments
                            </template>
                            <template v-else>
                                (Activity)
                            </template>
                        </data-table-sort-indicator>
                    </router-link>
                </th>
            </tr>
        </thead>

        <tbody :data-loading="loading">
            <tr v-for="person in people" :key="person.user_id">
                <td :data-is-current-sort="sort.endsWith('name') || sort.endsWith('signed-up')">
                    <div class="avatar-block">
                        <v-lazy width="40px" height="40px">
                            <router-link v-if="person.avatar" :to="{ name: 'user.detail', params: { id: person.user_id } }" tabindex="-1">
                                <img :src="person.avatar | formatImage(40, 40)" alt="" class="avatar" width="40" height="40">
                            </router-link>
                        </v-lazy>

                        <div>
                            <router-link :to="{ name: 'user.detail', params: { id: person.user_id } }" class="profile-link">
                                {{ person.first_name }} {{ person.last_name }}
                            </router-link>
                            <div>
                                {{ person.registered_at | formatDate('MMMM DD, YYYY') }}
                            </div>
                        </div>
                    </div>
                </td>

                <td :data-is-current-sort="sort.endsWith('contributed')" :title="person.lastContributionAt | fromNowDate">
                    <template v-if="person.last_participated_at">
                        {{ person.last_participated_at | formatDate }}
                    </template>
                    <template v-else>
                        Never
                    </template>
                </td>

                <td v-if="lastColumn" :data-is-current-sort="sort.endsWith('contributions')">
                    {{ person.participation_count || '0' }}
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import DataTableSortIndicator from '../../../components/DataTableSortIndicator.vue';
import { Person } from './Index.vue';

export default Vue.extend({
    components: {
        DataTableSortIndicator,
    },

    props: {
        sort: {
            type: String,
            default: '',
        },

        people: {
            type: Array as () => Person[],
            default: () => []
        },

        lastColumn: {
            type: String as () => 'posts' | 'comments' | null,
            default: null,
        },

        loading: {
            type: Boolean,
            default: false,
        },
    },
});
</script>

<style lang="postcss" scoped>
table {
    font-size: var(--type-interface);
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}

thead {
    border-block-end: 1px solid #8888;
    line-height: 1.2;
}

th {
    font-weight: inherit;
}

.sort-link {
    color: inherit;
    display: inline-block;
    margin: -1em -0.3em;
    padding: 1em 0.3em;
    text-decoration: none;
}

.sort-link[data-active] {
    font-weight: bold;
}

th,
td {
    padding: var(--spacing-3) var(--spacing-3);
    text-align: center;
}

tbody {
    transition: opacity 0.4s;
}

tbody[data-loading] {
    opacity: 0.5;
}

tbody > tr:first-child > td {
    padding-block-start: var(--spacing-5);
}

tbody > tr:last-child > td {
    padding-block-end: var(--spacing-5);
}

th:first-child:not([data-people-table-narrow] *),
td:first-child:not([data-people-table-narrow] *) {
    text-align: start;
}

[data-is-current-sort] {
    background: #8881;
}

.avatar-block {
    display: flex;
}

[data-people-table-narrow] .avatar-block {
    align-items: center;
    flex-direction: column;
}

.avatar-block:not([data-people-table-narrow] *) > :not(:first-child) {
    margin-inline-start: var(--spacing-2);
}

.avatar {
    border-radius: 50%;
    display: block;
    max-width: none;
    object-fit: cover;
}

.profile-link {
    font-size: var(--type-lead);
    font-weight: bold;
    text-decoration: none;
}
</style>
