<template>
    <!-- The wrapper prevents the parent from overriding the margins. -->
    <div>
        <component :is="tag" class="row-root" :style="style">
            <slot />
        </component>
    </div>
</template>

<script lang="ts">
import Vue from '@/vueTyped';

export default Vue.extend({
    props: {
        tag: { type: String, default: 'div' },
        gap: { type: String, default: 'var(--spacing-8)' },
        wrap: { type: Boolean, default: false },
        align: { type: String, default: null },
        justify: { type: String, default: null },
    },

    computed: {
        style(): Record<string, string | null> {
            return {
                '--gap': String(this.gap) === '0' ? '0px' : this.gap,
                flexWrap: this.wrap ? 'wrap' : null,
                alignItems: this.align,
                justifyContent: this.justify,
            };
        },
    },
});
</script>

<style lang="postcss" scoped>
.row-root {
    display: flex;
    /* Use `margin` instead of `gap` so the columns can be easily set as percents.
    There might be a better way to do this. */
    margin: calc(var(--gap) / -2);
}
</style>
