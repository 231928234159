<template>
    <component :is="tag" class="column-root" :style="{ '--column-width': width, alignSelf: align, flex: flex }" v-bind="$attrs">
        <slot />
    </component>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
export default Vue.extend({
    props: {
        tag: { type: [String, Function], default: 'div' },
        size: { type: [String, Number], default: -1 },
        flex: { type: String, default: null },
        align: { type: String, default: null },
    },

    computed: {
        width(): string | null {
            if (typeof this.size === 'string') return this.size;
            if (this.size !== -1) return `${this.size * 100}%`;
            return null;
        }
    },
});
</script>

<style lang="postcss" scoped>
    .column-root {
        margin: calc(var(--gap) / 2);
        flex-basis: calc(var(--column-width) - var(--gap));
    }
</style>
