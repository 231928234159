<template>
    <component :is="tag" class="box-root">
        <slot name="before" />

        <div class="content">
            <slot />
        </div>

        <slot name="after" />
    </component>
</template>

<script lang="ts">
import Vue from '@/vueTyped';

export default Vue.extend({
    props: {
        tag: { type: String, default: 'div' },
    },
});
</script>

<style lang="postcss" scoped>
    .box-root {
        border: 1px solid #8885;
        border-radius: var(--spacing-4);
    }

    .content {
        margin: var(--spacing-8);
    }
</style>
