<template>
    <div class="client-layout-container">
        <v-tabs background-color="secondary-light" dark centered class="nav">
            <template v-if="clientGroups.length > 1">
                <v-tab class="tab">
                    <base-menu :attach="false">
                        <template #activator="{ attrs, on }">
                            <button type="button" v-bind="attrs" v-on="on">
                                {{ selectedClientGroup ? selectedClientGroup.name : 'Client group' }}<!--
                                --><v-icon>expand_more</v-icon>
                            </button>
                        </template>

                        <v-card class="text-start">
                            <v-list-item v-for="clientGroup of clientGroups" :key="clientGroup.id" :to="{ params: { clientGroupSlug: clientGroup.slug } }" exact>
                                <v-list-item-title>
                                    {{ clientGroup.name }}
                                    <v-icon v-if="clientGroup === selectedClientGroup" right style="color: inherit;">check</v-icon>
                                </v-list-item-title>
                            </v-list-item>
                        </v-card>
                    </base-menu>
                </v-tab>
            </template>
            <v-tab :to="{ name: 'client-map-view', params: $route.params }" class="tab" active-class="active">{{ $t('map') }}</v-tab>
            <v-tab :to="{ name: 'people', params: $route.params }" class="tab" active-class="active">{{ $t('people') }}</v-tab>
            <v-tab :to="{ name: 'client-user-groups-index', params: $route.params }" class="tab" active-class="active">{{ $t('userGroups') }}</v-tab>
            <v-tab :to="{ name: 'client-surveys-index', params: $route.params }" class="tab" active-class="active">{{ $t('surveys') }}</v-tab>
        </v-tabs>

        <router-view v-if="selectedClientGroup" :client-group="selectedClientGroup" class="content" />
    </div>
</template>

<script lang="ts">
import { ClientGroup } from '@/types';
import Vue from '@/vueTyped';

export default Vue.extend({
    i18n: {
        messages: {
            en: {
                map: 'Map',
                people: 'People',
                userGroups: 'User Groups',
                surveys: 'Surveys',
            },
        },
    },

    computed: {
        clientGroups(): ClientGroup[] {
            return this.$store.state.account.currentUser?.clientGroups ?? [];
        },

        selectedClientGroup(): ClientGroup | null {
            const clientGroupSlug = String(this.$route.params.clientGroupSlug ?? '');
            return this.clientGroups.find(cg => cg.slug === clientGroupSlug) ?? null;
        },
    },

    watch: {
        '$route.params.clientGroupSlug': {
            immediate: true,
            handler() {
                this.ensureSlugIsSet();
            },
        },

        clientGroups: {
            immediate: true,
            handler() {
                this.ensureSlugIsSet();
            },
        },
    },

    methods: {
        ensureSlugIsSet() {
            if (this.clientGroups.length > 0 && !this.selectedClientGroup) {
                this.$router.replace({
                    params: {
                        ...this.$route.params,
                        clientGroupSlug: this.clientGroups[0].slug,
                    },
                });
            }
        },
    },
});
</script>

<style lang="postcss" scoped>
.client-layout-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.nav {
    flex: 0 0;
}

.tab {
    color: var(--color-secondary-text) !important;
}

.active {
    color: var(--color-tertiary) !important;
}

.content {
    flex: 1 1 auto;
}
</style>
