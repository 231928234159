<template>
    <component :is="tag || 'base-fieldset'">
        <template v-if="$slots.label || $attrs.label" #legend>
            <slot name="label">{{ $attrs.label }}</slot>
        </template>

        <div class="line">
            <span class="label">
                <slot name="fromLabel">From:</slot>
            </span>

            <base-datetime-picker
                :value="value[0] || ''"
                :default-value="value[1] || 'now'"
                :max="value[1] || 'now'"
                class="buttons"
                @input="handleChange(0, $event)"
            />
        </div>

        <div class="line">
            <span class="label">
                <slot name="toLabel">To:</slot>
            </span>

            <base-datetime-picker
                :value="value[1] || ''"
                :default-value="value[0] || 'now'"
                :min="value[0] || ''"
                max="now"
                class="buttons"
                @input="handleChange(1, $event)"
            />
        </div>

        <div class="line">
            <span class="label" />

            <div v-if="rangeDescription" class="description">
                <slot name="description" :range="rangeDescription">{{ $t('about') }} {{ rangeDescription }}</slot>
            </div>
        </div>

        <template v-if="$slots.hint || $attrs.hint" #hint>
            <slot name="hint">{{ $attrs.hint }}</slot>
        </template>

    </component>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import moment from 'moment';

// Note that while the value of BaseDatetimePicker is always a string (an empty string if there's no value set),
// this component's value is always an array of two values, and unset dates in the array are null.

export default Vue.extend({
    i18n: {
        messages: {
            en: { about: 'About' },
            es: { about: 'Acerca de' },
        }
    },

    props: {
        tag: {
            type: String,
            default: '',
        },

        value: {
            type: Array as () => (string | null)[],
            default: () => [null, null],
        },
    },

    computed: {
        rangeDescription(): string | null {
            return this.value[0] && this.value[1] ? moment(this.value[0]).to(this.value[1], true) : null;
        },
    },

    methods: {
        handleChange(index: number, value: string) {
            let result = [...this.value];
            result[index] = value;
            result = result.map(value => value || null);

            this.$emit('input', result);
        },
    },
});
</script>

<style lang="postcss" scoped>
.line {
    align-items: center;
    display: flex;
    margin: var(--spacing-2) 0;
}

.label {
    flex: 0 0 5ch;
    margin-inline-end: 1ch;
}

.buttons {
    flex: 1 1 auto;
}

.description {
    color: var(--color-success);
    flex: 1 1 auto;
    font-style: italic;
}
</style>
