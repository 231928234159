<template>
    <base-page-content>
        <content-block v-if="surveys.length === 0">
            <p class="text-center">
                No surveys
                <template v-if="clientGroup">
                    for
                    <b>{{ clientGroup.name }}</b>
                </template>
            </p>
        </content-block>

        <template v-else>
            <content-block v-if="activeSurveys.length > 0">
                <content-box>
                    <content-block>
                        <h2>Active surveys</h2>
                    </content-block>

                    <layout-row wrap>
                        <template v-for="survey in activeSurveys">
                            <layout-column :key="survey.id" :size="$vuetify.breakpoint.sm ? 1 : 1/2" :tag="$options.components.SurveyCard" :survey="survey" />
                        </template>
                    </layout-row>
                </content-box>
            </content-block>

            <content-block v-if="inactiveSurveys.length > 0">
                <content-box>
                    <content-block>
                        <h2>Inactive surveys</h2>
                    </content-block>

                    <layout-row wrap>
                        <template v-for="survey in inactiveSurveys">
                            <layout-column :key="survey.id" :size="$vuetify.breakpoint.sm ? 1 : 1/2" :tag="$options.components.SurveyCard" :survey="survey" />
                        </template>
                    </layout-row>
                </content-box>
            </content-block>
        </template>
    </base-page-content>
</template>

<script lang="ts">
import ContentBlock from '@/ui/ContentBlock.vue';
import ContentBox from '@/ui/ContentBox.vue';
import LayoutColumn from '@/ui/LayoutColumn.vue';
import LayoutRow from '@/ui/LayoutRow.vue';
import SurveyCard from './Index/SurveyCard.vue';
import Vue from '@/vueTyped';
import { ClientGroup, Survey } from '@/types';

export default Vue.extend({
    components: {
        ContentBlock,
        ContentBox,
        LayoutColumn,
        LayoutRow,
        // eslint-disable-next-line vue/no-unused-components
        SurveyCard,
    },

    props: {
        clientGroup: { type: Object as () => ClientGroup, required: true },
    },

    data() {
        return {
            surveys: [] as Partial<Survey>[],
        };
    },

    computed: {
        activeSurveys(): any[] {
            return this.surveys.filter(s => !s.closedAt || new Date(s.closedAt).valueOf() > Date.now());
        },

        inactiveSurveys(): any[] {
            return this.surveys.filter(s => !this.activeSurveys.includes(s));
        },
    },

    watch: {
        'clientGroup.id': {
            immediate: true,
            handler: 'fetchSurveys',
        },
    },

    methods: {
        async fetchSurveys() {
            this.surveys = [];
            if (this.clientGroup) {
                const { data: { surveys } } = await this.$store.state.apiClient.get(`/surveys?clientGroupId=${this.clientGroup.id}`);
                this.surveys = surveys;
            }
        }
    },
});
</script>

<style lang="postcss" scoped>
.tag {
    background: var(--color-light);
    border-radius: 0.2em;
    display: inline-block;
    padding: 0.2em 0.8em;
}
</style>
