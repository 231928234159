<template>
    <div class="wrap">
        <h2>{{ $t('head') }}</h2>
        <p>{{ $t('body') }}</p>
        <base-button :href="newMapUrl" color="primary" block>{{ $t('cta') }}</base-button>
    </div>
</template>

<script lang="ts">
import { INSIGHTS_ORIGIN } from '@/config';
import { defineComponent } from 'vue';

export default defineComponent({
    i18n: {
        messages: {
            en: {
                head: 'The map has been updated',
                body: 'Open it in the new experience from the button below. (Soon the whole ISeeChange Partner View will be there).',
                cta: 'Open “map” view'
            },

            es: {
                head: 'Se ha actualizado el mapa',
                body: 'Ábrala en la nueva experiencia desde el botón de abajo. (Pronto estará toda la vista de socios de ISeeChange).',
                cta: 'Abrir la vista "mapa"'
            },
        },
    },

    computed: {
        newMapUrl(): string {
            const url = new URL(INSIGHTS_ORIGIN);
            url.searchParams.set('from', 'main');
            return url.href;
        },
    },
});
</script>

<style>
.wrap {
    margin: 10vw auto;
    max-width: 512px;
    padding-inline: var(--spacing-4);
    text-align: center;
}
</style>
