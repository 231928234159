<template>
    <component :is="tag" class="block-root">
        <slot />
    </component>
</template>

<script lang="ts">
import Vue from '@/vueTyped';

export default Vue.extend({
    props: {
        tag: { type: String, default: 'div' },
    },
});
</script>

<style lang="postcss" scoped>
.block-root {
    margin: var(--spacing-8) 0;
}

:deep(h2),
:deep(h3),
:deep(h4) {
    font-size: var(--type-subtitle);
    font-family: var(--type-sans);
    font-weight: bold;
    margin: 0;
}

:deep(h4) {
    font-size: var(--type-lead);
}
</style>
