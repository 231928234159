<template>
    <div>
        <div class="mb-12">
            <base-select
                v-model="filters.activity"
                label="Activity"
                :items="activityOptions"
                hide-details
                class="mb-n3"
            />

            <transition-expand :value="filters.activity">
                <base-fieldset class="activity-date-range-inputs">
                    <template v-if="filters.relativeDates" #legend>In the last...</template>

                    <template v-if="filters.relativeDates">
                        <div class="my-3">
                            <base-button outlined :pressed="filters.last === 'ever'" class="mr-2" @click="filters.last = 'ever'">Any time</base-button>
                            <base-button outlined :pressed="filters.last === 'day'" class="mr-2" @click="filters.last = 'day'">Day</base-button>
                            <base-button outlined :pressed="filters.last === 'week'" class="mr-2" @click="filters.last = 'week'">Week</base-button>
                            <base-button outlined :pressed="filters.last === '30-days'" @click="filters.last = '30-days'">30 days</base-button>
                        </div>

                        <base-button faux-link @click="filters.relativeDates = false">Custom date and time</base-button>
                    </template>

                    <template v-else>
                        <time-range-picker
                            tag="div"
                            :value="[filters.after, filters.before]"
                            @input="([start, end]) => { filters.after = start; filters.before = end; }"
                        >
                            <template #fromLabel>After:</template>
                            <template #toLabel>Before:</template>
                            <template #description>
                                <span /><!-- Blank description. -->
                            </template>
                        </time-range-picker>

                        <base-button faux-link @click="filters.relativeDates = true">Relative date and time</base-button>
                    </template>
                </base-fieldset>
            </transition-expand>
        </div>

        <base-select
            v-if="neighborhoods.length !== 0"
            :value="filters.neighborhood"
            label="Neighborhood"
            :items="neighborhoodOptions"
            item-text="name"
            item-value="id"
            class="mb-6"
            hint="These are the neighborhoods that intersect with your project's regions"
            persistent-hint
            @change="filters.neighborhood = $event === neighborhoodOptions[0].name ? null : $event"
        />

        <base-fieldset>
            <template #legend>Posts and comments</template>

            <v-slider
                v-model="filters.contributionsMin"
                :min="0"
                :max="50"
                :step="10"
                ticks="always"
                thumb-label="always"
                class="mt-8"
            >
                <template #thumb-label="{ value }">
                    <template v-if="value === 0">Any</template>
                    <template v-else>{{ value }}+</template>
                </template>
            </v-slider>
        </base-fieldset>
    </div>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import TimeRangePicker from '@/components/TimeRangePicker.vue';
import { Neighborhood } from './Index.vue';
import TransitionExpand from '@/components/TransitionExpand.vue';

type PeopleQuery = {
    group?: string;
    region?: string;
    activity?: string,
    last?: string;
    after?: string;
    before?: string;
    neighborhood?: string;
    contributionsMin?: string;
};

export default Vue.extend({
    components: {
        TimeRangePicker,
        TransitionExpand,
    },

    props: {
        query: {
            type: Object,
            default: () => ({}),
        },

        neighborhoods: {
            type: Array as () => Neighborhood[],
            default: () => [],
        },
    },

    data() {
        return {
            filters: {
                activity: 'posting',
                relativeDates: true,
                last: 'ever' as string,
                after: null as string | null,
                before: null as string | null,
                neighborhood: null as string | null,
                contributionsMin: 0 as number,
            },
        };
    },

    computed: {
        activityOptions(): { value: string, text: string }[] {
            return [
                { value: 'posting', text: 'Posted' },
                { value: 'registered', text: 'Signed up' },
                { value: 'commenting', text: 'Commented' },
                { value: 'inactive', text: 'None' }
            ];
        },

        neighborhoodOptions(): (Neighborhood | (Omit<Neighborhood, 'id'> & { id: null }))[] {
            return [
                { id: null, name: '(Any neighborhood)' },
                ...this.neighborhoods,
            ];
        },

        externalValue(): PeopleQuery {
            const activityParams = {
                activity: undefined as string | undefined,
                last: undefined as string | undefined,
                after: undefined as string | undefined,
                before: undefined as string | undefined,
            };

            if (this.filters.activity) {
                activityParams.activity = this.filters.activity;

                if (this.filters.relativeDates) {
                    activityParams.last = this.filters.last;
                } else if (this.filters.after || this.filters.before) {
                    activityParams.after = this.filters.after ?? undefined;
                    activityParams.before = this.filters.before ?? undefined;
                } else {
                    activityParams.last = 'ever';
                }
            }

            return {
                ...activityParams,
                neighborhood: this.filters.neighborhood ?? undefined,
                contributionsMin: this.filters.contributionsMin === 0 ? undefined : String(this.filters.contributionsMin),
            };
        },
    },

    watch: {
        query: {
            deep: true,
            immediate: true,
            handler(query) {
                this.syncFiltersWithQuery(query);
            },
        },

        externalValue: {
            deep: true,
            handler(externalValue) {
                this.$emit('input', externalValue);
            },
        },
    },

    methods: {
        syncFiltersWithQuery(query: PeopleQuery) {
            this.filters.activity = query.activity ?? this.activityOptions[0].value;
            this.filters.relativeDates = !(query.after || query.before);
            this.filters.last = query.last ?? 'ever';
            this.filters.after = query.after ?? null;
            this.filters.before = query.before ?? null;
            this.filters.neighborhood = query.neighborhood ?? null;
            this.filters.contributionsMin = query.contributionsMin ? parseFloat(query.contributionsMin) : 0;
        },

        // Called by the parent, which is bad, so make it ugly.
        publicSyncFiltersWithQuery(query: PeopleQuery) {
            return this.syncFiltersWithQuery(query);
        },
    },
});
</script>

<style lang="postcss" scoped>
.activity-date-range-inputs {
    background: #8882;
    border-radius: 0 0 var(--spacing-1) var(--spacing-1);
    padding: 1em;
}
</style>
